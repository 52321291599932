define("discourse/plugins/swapd-spy-tool/discourse/routes/preferences-saved-searches", ["exports", "@ember/service", "discourse/routes/restricted-user"], function (_exports, _service, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesSavedSearches extends _restrictedUser.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    setupController(controller, model) {
      if (!model.can_use_saved_searches) {
        return this.router.transitionTo("preferences.account");
      }
      const frequencyOptions = ["immediately", "hourly", "daily", "weekly"];
      const savedSearchesMinInterval = model.saved_searches_min_interval || 0;
      model.saved_searches = model.saved_searches.map(search => {
        const currentFrequencyIndex = frequencyOptions.indexOf(search.frequency);
        const updatedFrequency = currentFrequencyIndex < savedSearchesMinInterval ? frequencyOptions[savedSearchesMinInterval] : search.frequency;

        // Return a new object with the updated frequency
        return {
          ...search,
          frequency: updatedFrequency
        };
      });
      const scopedIds = model.spy_scoped_categories || "";
      const scopedCategories = scopedIds.split("|").filter(id => id.trim() !== "").map(id => ({
        id: parseInt(id, 10)
      }));
      console.log(scopedCategories);
      controller.setProperties({
        model,
        scopedCategories: scopedCategories,
        savedSearches: model.saved_searches || []
      });
    }
  }
  _exports.default = PreferencesSavedSearches;
});